:global(.slider) {
  width: 100%;
  height: 2rem;
}

.sliderHorizontal {
  width: 100%;
  border-style: solid;
  border-color: #cccccc;
  border-image: initial;
}

.sliderThumb {
  top: 0.18rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1rem;
  line-height: 1.5rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  text-align: center;
  background-color: #becfd3;
  color: #54818c;
  font-weight: 900;
  cursor: pointer;
  box-shadow: -1px 1px 3px #777777;
}

.sliderTrack {
  top: 0.9rem;
  height: 5px;
  background-color: #e6e6e6;
  border-radius: 1rem;
}

.sliderTrack:nth-child(1) {
  background-color: #54818c;
}
