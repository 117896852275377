.container_inline {
  width: 100%;
  /*border: 1px solid black;*/
  z-index: 1;
  border-radius: 0.3rem;
}

.container {
  width: 100%;
  /*border: 1px solid black;*/

  z-index: 1;
  position: fixed;
  top: 2rem;
}

.colorbar {
  height: 100%;
  border-radius: 0 0.3rem 0.3rem 0;
}
