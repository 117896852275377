.modal_modal {
  z-index: 1;
  background-color: #000000a6;
  width: 100vw;
  height: 100vh;
  position: absolute;
  align: center;
  top: 0;
  left: 0;
  overflow: hidden;
}

b {
  font-size: 1.2rem;
  font-weight: 900;
}

.modalbox .buttons {
  margin: 2rem;
}

.hidden {
  display: none;
}

.modal_modal {
  z-index: 1;
  background-color: #000000a6;
  width: 100vw;
  height: 100vh;
  position: absolute;
  align: center;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
}

b {
  font-size: 1.2rem;
  font-weight: 900;
}

.modalbox {
  margin: auto;
  z-index: 1;
  position: relative;
  border-radius: 0.2rem;
  box-shadow: 3px 4px 10px #00000038;
  font-size: 1.1rem;
  padding: 0rem;
  text-align: left;
  color: #00000096;
  width: 90vw;
  min-height: 20rem;
  max-height: 90vh;
  overflow: hidden;
  background: black;
}
