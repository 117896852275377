.container {
  width: 21rem;
  height: 1rem;
  border: 1px dotted #00000033;
  border-radius: 0.3rem;
  background-color: #00000033;
}

.stripes {
    background-color: purple;
  background-image:
    repeating-linear-gradient(-45deg,
    transparent,
    transparent .5rem,
    #cccccc22 .5rem,
    #cccccc22 1rem);
  background-size: 200% 200%;
  animation: barberpole 0s linear infinite;
}

@keyframes barberpole {
  0% {
 	 background-position:0% 0%;
  }
  100% {
 	 background-position: 100% 100%;
  }
}
