.container {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  line-height: 1.8rem;
  margin: 0 auto 0 auto;
  width: 10rem;
}

.avatarImage {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #000000a6;
  width: 5rem;
  height: 5rem;
  margin: 0 auto 0 auto;
}

.email {
  color: white;
  margin: 0 auto 0 auto;
  width: 10rem;
  text-align: center;
  align: center;
}

.avatarIMG {
  height: 5rem;
}

.dropzone_container {
  border-radius: 6rem;
  height: 6rem;
  width: 6rem;
  margin: auto;
  border: 1px solid #e0e0e0;
  overflow: hidden;
}
