.outer {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.inner,
.innerNatural {
  margin: auto;
  z-index: 1;
  background-color: #ffffffde;
  border-radius: 0.2rem;
  box-shadow: 3px 4px 10px #00000038;
  max-width: 90vw;
  max-height: 90vh;
  min-width: 15rem;
}
.scrollable {
  overflow: scroll;
}

.inner {
  width: 80%;
  max-width: 50rem;
  height: 75vh;
  min-height: 20rem;
  max-height: 75vh;
}
