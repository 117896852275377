.demoLabel {
  position: absolute;
  color: #004dff4d;
  z-index: 1;
  font-weight: 900;
  font-size: 9rem;
  top: 2.25rem;
  right: 0.5rem;
  transform: rotate(-10deg);
}

.shakeMe {
  animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.loadingBar {
  z-index: 1;
  top: 2rem;
  left: 0;
  width: 100%;
  background-color: #349eb7;
  height: 0.2rem;
  position: absolute;
}

.workIndicator {
  position: fixed;
  bottom: 1.2rem;
  left: -1px;
}

.workIndicatorCircle {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 20px;
  border-radius: 50%;
}

.workIndicatorSpinner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border-right: 2px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid #349eb7;
  border-left: 2px solid #349eb7;
  border-bottom: 2px solid #349eb7;
  animation: rotate--spinner 1.6s infinite;
}

@keyframes rotate--spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
