:global(html), :global(body) {
  height: 100%;
}

:global(body) {
  margin: 0;
  background-color: #e2e2e2
}

.container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 50rem;
  max-width: 95vw;
  background-color: #dadada;
  min-height: 30vh;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  padding: 5rem;
  border-radius: .3rem;
}

.link {
  opacity: .9;
  text-decoration: underline;
}

.link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.title {
  font-size: 4rem;
  color: #cccccc;
  font-weight: 900;
}

.icon {
  font-size: 10rem;
  color: #cccccc;
  margin: 2rem 2rem 1rem 2rem;
}

.errorText, .message {
  color: #717171;
  font-size: 1.2rem;
  width: 100%;
  max-width: 30rem;
  margin: auto;
  font-weight: bold;
}

.errorText {
  font-family: monospace;
  margin: 1rem auto 1rem;
}
