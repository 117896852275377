.dropzone_container {
  border: 0;
  height: 100%;
  width: 10rem;
  height: 10rem;
  border: .2px solid #00000021;
  background-color: gray;
  overflow: hidden;
  margin: 0.5rem;
}

.dropZone,
.dropZone_active {
  position: relative;
  align-items: center;
  align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0rem;
  display: flex;
  height: 100%;
  transition: background-image 0.25s linear;
  width: 100%;
}

.hasButton {
  top: -3.01rem;
}

.dropZone_children {
  display: flex;
  flex-grow: 1;
  min-height: 30rem;
}

.dropZone_children_active,
.dropZone_active {
  background-color: rgba(0, 0, 0, .5);
  transition: background-color 0.25s linear;
  opacity: 0.7;

}

.dragMessage {
  margin: auto;
  background: #00000080;
  width: 80%;
  color: white;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.5rem;
  font-size: 0.5rem;
}

/* Spinner */
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.doublebounce1, .doublebounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.doublebounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
