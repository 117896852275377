.table {
  width: 100%;
  height: 100%;
}

.table td {
  width: 100%;
  text-align: center;
}

.table tr {
  border: 1px 0 0 0 solid #00000010;
  padding: 0.4rem;
}

.tableRow,
.tableRowHead {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  min-height: 2rem;
}

.selected {
  background-color: #dceff5;
  border-radius: 0rem;
}

.tableRowHead {
  background-color: #54818c66;
  font-weight: 900;
}

.tableRowCell_buttons {
  white-space: nowrap;
}

.button {
  padding: 0.5rem 0 0.2rem 0;
  border-radius: 0.3rem;
  border: 1px solid black;
  width: 3rem;
  line-height: 1rem;
}

.hoverable:hover {
  background-color: #54818c22;
  color: white;
}

.playbackBlock {
  display: flex;
  flex-direction: column;
}

.playbackBlockHeader {
  font-size: 1.2rem;
  font-weight: 900;
  color: #85c9de;
  margin: 0 0 .5rem 0;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

:global(.ripple_active) {
  border: 10px solid white;
  background-color: #e8ffb14f;
  background-size: 100%;
  transition: background 0s;
}

/* Button style */

.ripple {
  position: fixed;
  border: none;
  height: 10rem;
  width: 10rem;
  border-radius: 100%;
  top: calc(50vh - 4.4rem);
  left: calc(50vw - 5rem);
}
