.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #e4e4e4;
}

.image {
  height: 70px;
  width: 70px;
  border: 1px solid #54818c61;
}

.serverBlock {
  background-color: white;
  text-align: center;
  font-size: 0.2rem;
  border: 3px solid transparent;
}

.serverBlock img {
  height: 70px;
  width: 70px;
}
.selected {
  border: 3px solid orange;
}
