.container {
  background-color: rgb(79, 79, 79);
  position: fixed;
  top: 0.5rem;
  left: 0;
  width: 12.5rem;
  margin: 2rem 0rem 0 0;
  padding: 0.5rem 0 0.5rem 1rem;
  border-radius: 0 0 0.3rem 0.3rem;
  z-index: 1;
}
.iconButton {
  margin: -0.2rem 0.5rem 0 -0.4rem;
  color: white;
  height: 1rem;
}
.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10rem;
}
.hidden {
  display: none;
}
.collapsed {
  width: 2rem;
}
