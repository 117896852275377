.container {
  margin: 0 0 1rem 0;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collapsed {
  display: none;
}

.uncollapsed {
  display: block;
}
