.container{
  margin:1rem;
  min-width: 20rem;
  text-align: left;
}

.statusHidden{
  margin:5rem 0 0 0 ;
}

.dialogMessage{
  margin:0 0 1rem 0;
  position: relative;
  padding: 1rem;
  color: white;
  background-color: #57838e;
  border-radius: 0.3rem;
}

.avatarImage {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #000000a6;
  width: 3rem;
  height: 3rem;
  background-color: white;
}
.avatarImage img{
  height:3rem;
}
.formButtons{
  margin: 5rem 0 0 0;
  text-align: center;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

}

.formFields{
  width: 20rem;
  margin: auto;
}

.formFields input{
  margin-bottom: 1rem;
}

.column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item{
  flex-grow: 1;
  margin-right: 1rem;
  min-width: 10rem;
}

.email{
  opacity: .5;
}

.notSame input{
  border:1px solid orange;
}
