.container {
  width: 100vw;
  position: fixed;
  padding: 1rem;
  z-index: 1;
  top: 3rem;
}
.previous_button,
.next_button {
  display: inline-block;
  padding: 0;
  text-align: center;

  opacity: 0.7;
  color: black;
  background-color: white;

  border-radius: 0.3rem;
  border: 1px solid #00000022;
  min-width: 4rem;
  height: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0 2.8rem 0 0;
  font-weight: 900;
}
.next_button {
  position: absolute;
  right: 0;
}
.next_button:hover,
.previous_button:hover {
  opacity: 0.7;
  color: black;
  background-color: #d0d0d0;
}
.disabled,
.disabled:hover {
  color: grey;
  background-color: darkGrey;
  opacity: 0.3;
}
