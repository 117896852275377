.dropZone,
.dropZone_active {
  margin: 0;
  padding: 0;
  display: 'flex';
}

.dropZone_active {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s linear;
  opacity: 0.7;
}
