.tooltip {
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  background-color: #ffffffbf;
  padding: 0;
  width: 8rem;
  height: 3rem;
  box-shadow: -1px 0px 7px #000000;
  border-radius: 0.3rem;
  overflow: hidden;
  text-align: center;
}

.contentTitle,
.content {
  margin: auto;
}

.contentTitle {
  opacity: 0.5;
}

.contextMenu_item_disabled,
.contextMenu_item {
  padding: 0.2rem 1rem;
}

.contextMenu_item_disabled {
  opacity: 0.2;
}

.contextMenu_item:hover {
  background-color: #54818c88;
  color: white;
}
