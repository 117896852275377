.userModal {
  min-width: '40rem';
}

.container {
  margin: 1rem;
  min-width: 20rem;
}

.avatarImage {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #000000a6;
  width: 3rem;
  height: 3rem;
  background-color: white;
}
.avatarImage img {
  height: 3rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  flex-grow: 1;
  margin-right: 1rem;
  min-width: 10rem;
}

.gridContainer {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
}

.colRight {
  text-align: right;
  flex-grow: 1;
}

.email {
  opacity: 0.5;
}
