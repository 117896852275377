.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.8rem;
  margin: 1rem auto 0 auto;
  width: 15rem;
  border: 0px solid red;
  align-items: center;
}

.container input {
  border: 1px solid #e4e4e4;
  margin: 0 0 0.5rem 0rem;
  width: 10rem;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0.4rem;
  color: #54818c;
  font-family: sans-serif;
  line-height: 0.8rem;
  height: 0.8rem;
  border-radius: 0.3rem;
}
