.container{
  color:white;
  position: fixed;
  width: 100vw;
  background-color: #F5A623;
  top:0;
  left:0;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
  padding: 0 0 0 1rem;
  font-family: sans-serif;
  box-shadow: 3px 4px 10px #949494;
}
