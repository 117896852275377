.buttonBorderless,
.button {
  display: inline-block;
  padding: 0;
  text-align: center;
  background-color: #d0d0d0;

  border-radius: 0.3rem;
  border: 1px solid #00000022;
  min-width: 4rem;
  height: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0 0.3rem 0 0;
  font-weight: 900;
}

.buttonBorderless {
  background-color: transparent;
  border: 0px;
}

.buttonBorderless:hover,
.button:hover {
  opacity: 0.7;
  color: black;
}

.disabled:hover, .disabled{
  color:grey;
  opacity: 0.7;
}
