.dialog_modal {
  z-index: 1;
  background-color: #0000008c;
  position: fixed;
  align: center;

  padding: 1rem 0 0 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content {
  white-space: normal;
  overflow: scroll;
  text-align: center;
  max-height: 70vh;
  flex-grow: 1;
  padding: 0 0 0 0.3rem;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.content hr {
  border: 0;
  height: 1px;
  background: #ccc;
  /*background-image: linear-gradient(to right, #333, #ccc, #333);*/
}

.content h1 {
  margin: 0 0 0 -0.3rem;
  font-size: 1.5rem;
  font-weight: 900;
}
.content b {
  font-weight: 900;
  font-size: 1rem;
}

.dialogbox {
  border-radius: 0.3rem;
  z-index: 1;
  background-color: #fffffff0;
  width: auto;
  max-width: 30rem;
  padding: 1rem;
  text-align: center;
  height: auto;
  max-height: 95vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000096;
}

.dialogbox .buttons {
  margin: 1rem 0 0 0;
  flex-grow: 1;
}

.dialogbox input {
  margin: 0 0.2rem;
  width: 6rem;
}

.hidden {
  display: none;
}
